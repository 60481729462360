<template>
  <fd-form class="link-property-owner" @submit="$emit('submit', form)">
    <fd-input
      v-model="form.mobileNumber"
      class="px-1 mb-2"
      label="Owner Phone No."
      name="ownerMobileNumber"
      type="text"
      :validators="[validator.required]"
    ></fd-input>

    <div class="d-flex justify-content-end mt-4">
      <fd-button @click="$emit('close')">Cancel</fd-button>
      <fd-button type="submit" class="main ml-1">Link</fd-button>
    </div>
  </fd-form>
</template>

<script>
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  components: {},
  mixins: [],
  props: {},
  data: function () {
    return {
      form: {
        mobileNumber: ""
      },
      validator: {
        required
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
